<template>
  <common-modal
    @close="$emit('close')"
    height="h-max max-h-common-modal"
    width="w-full min-w-common-modal max-w-lg-common-modal">
    <div>
      <h1 class="invite-modal-header">
        {{ $lang.inviteAmbassadorsModal_Title }}
      </h1>
      <div class="invite-modal-body">
        <p class="invite-modal-sub-header">
          {{ $lang.inviteAmbassadorsModal_To }}
        </p>

        <div class="invite-modal-list-container">
          <div
            v-for="(ambassador, index) of invitees"
            :key="`ambassador-to-invite-${index}`"
            class="invite-modal-list-item">
            <common-input
              :class="{
                'invite-modal-list-item-error':
                  rowsWithErrors[ambassador.email],
              }"
              :placeholder="$lang.general_Email"
              v-model="ambassador.email" />

            <common-input
              class="invite-modal-list-item-input"
              :placeholder="$lang.general_FirstName"
              v-model="ambassador.first" />

            <common-input
              class="invite-modal-list-item-input"
              :placeholder="$lang.general_LastName"
              v-model="ambassador.last" />

            <svg
              v-if="invitees.length > 1"
              xmlns="http://www.w3.org/2000/svg"
              class="invite-modal-list-item-delete"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              @click="removeAmbassador(index)">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>

        <div class="invite-modal-actions">
          <div>
            <common-button
              variant="text"
              width="auto"
              @click="$refs['fileupload'].click()">
              <mdicon name="tray-arrow-up" class="invite-modal-button-icon" />
              {{ $lang.inviteAmbassadorsModal_Import }}
              <input
                type="file"
                ref="fileupload"
                @change="importCsv"
                accept=".csv"
                name="file"
                id="file"
                class="hidden" />
            </common-button>

            <common-button
              variant="text"
              width="auto"
              @click="exportCsvTemplate">
              <mdicon name="tray-arrow-down" class="invite-modal-button-icon" />
              {{ $lang.inviteAmbassadorsModal_DownloadTemplate }}
            </common-button>
          </div>

          <common-button variant="text" width="auto" @click="addAmbassadorRow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="invite-modal-button-icon"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>

            {{ $lang.inviteAmbassadorsModal_AddMore }}
          </common-button>
        </div>

        <common-button
          class="invite-modal-send-button"
          :disabled="!invitees.length || sendingInvite"
          :loading="sendingInvite"
          width="w-40"
          @click="sendInvites"
          >{{ $lang.inviteAmbassadorsModal_Send }}</common-button
        >
      </div>
    </div>
  </common-modal>
</template>

<script>
  import { mapGetters } from "vuex";
  import { GET_CURRENT_USER, GET_BRANDING } from "@/store/getters.type";
  import { CREATE_BULK_INVITE } from "@/store/actions.type";
  import CommonModal from "../Modal.vue";
  import validationService from "@/services/validations.service";
  import ambassadorService from "@/components/dashboard/ambassadors/ambassadors.service.js";
  import { exportCsvFile, importCsvFile } from "@/shared/utils.js";

  export default {
    name: "invite-ambassador-modal",
    props: ["ambassadors", "ambIndexes", "selectedAmbassadors"],
    components: {
      CommonModal,
    },
    data() {
      return {
        invitees: [
          {
            email: "",
            first: "",
            last: "",
          },
        ],
        rowsWithErrors: {},
        sendingInvite: false,
      };
    },

    computed: {
      ...mapGetters({
        getCurrentUser: GET_CURRENT_USER,
        getBranding: GET_BRANDING,
      }),
    },

    methods: {
      addAmbassadorRow() {
        this.invitees.push({
          email: "",
          first: "",
          last: "",
        });
      },
      removeAmbassador(index) {
        this.$delete(this.invitees, index);
      },
      targetInput(e) {
        if (e.target.lastChild) e.target.lastChild.focus();
      },
      importCsv(e) {
        const csvFile = e.target.files[0];
        const mimeTypes = ["text/csv", "application/vnd.ms-excel"];

        if (mimeTypes.includes(csvFile.type)) {
          e.target.value = ""; // reset so same file can be uploaded again
          const reader = new FileReader();
          reader.readAsText(csvFile);

          reader.onload = async (event) => {
            const csv = event.target.result;
            const data = importCsvFile(csv).data;

            const invitees = [];
            for (const row of data) {
              if (row.Email) {
                invitees.push({
                  email: row.Email,
                  first: row["First Name"],
                  last: row["Last Name"],
                });
              }
            }
            if (invitees.length === 0) {
              this.$toasted.show(
                this.$lang.inviteAmbassadorsModal_NothingToImportError,
                this.$toastedFailure
              );
            } else {
              // remove initial blank row
              if (this.invitees.length === 1 && !this.invitees[0].email) {
                this.invitees = invitees;
              } else {
                this.invitees.push(...invitees);
              }
              this.validateInvites();
            }
          };
        }
      },
      exportCsvTemplate() {
        const fields = [
          { label: "Email", value: "email" },
          { label: "First Name", value: "first" },
          { label: "Last Name", value: "last" },
        ];

        const exportList = [
          {
            email: "tokyo1@gmail.com",
            first: "Leonard",
            last: "Francois",
          },
          {
            email: "tokyo2@gmail.com",
            first: "Huston",
            last: "Adeyemi",
          },
          {
            email: "tokyo3@gmail.com",
            first: "Manuel",
            last: "Sharron",
          },
        ];

        exportCsvFile(exportList, "Ambassador_CSV_Template.csv", { fields });
      },
      validateInvites: async function () {
        const ambassadorList = {};
        this.rowsWithErrors = {};

        for (let ambassador of this.invitees) {
          if (
            !ambassador.email.length ||
            !ambassador.first.length ||
            !ambassador.last.length
          ) {
            this.$toasted.show(
              this.$lang.inviteAmbassadorsModal_RequiredFieldsError,
              this.$toastedFailure
            );
            return false;
          }

          if (!validationService.isEmailValid(ambassador.email)) {
            this.$set(this.rowsWithErrors, ambassador.email, true);
            this.$toasted.show(
              this.$lang.inviteAmbassadorsModal_InvalidEmailError.format(
                ambassador.email
              ),
              this.$toastedFailure
            );
            return false;
          }

          if (ambassadorList[ambassador.email]) {
            this.$toasted.show(
              this.$lang.inviteAmbassadorsModal_DuplicateEmailEntryError.format(
                ambassador.email
              ),
              this.$toastedFailure
            );
            return false;
          } else {
            ambassadorList[ambassador.email] = ambassador;
          }
        }

        const response = await ambassadorService.checkIfEmailExists(
          Object.keys(ambassadorList)
        );
        if (response?.length) {
          response.forEach((obj) => {
            this.$set(this.rowsWithErrors, obj.email, true);
          });
          this.$toasted.show(
            this.$lang.inviteAmbassadorsModal_EmailsInUseError,
            this.$toastedFailure
          );
          return false;
        }

        return true;
      },
      sendInvites: async function () {
        const isValid = await this.validateInvites();
        if (!isValid) return;

        const data = {
          inviteArray: this.invitees,
          message: this.$lang.inviteAmbassadorsModal_InviteMessage.format(
            this.getCurrentUser.first,
            this.getCurrentUser.last,
            this.getBranding.displayName || this.getBranding.name
          ),
        };

        this.sendingInvite = true;
        const response = await this.$store.dispatch(CREATE_BULK_INVITE, data);

        if (response) {
          this.$toasted.show(
            this.$lang.inviteAmbassadorsModal_InviteSentMessage.format(
              this.invitees.length
            ),
            this.$toastedSuccess
          );
          return this.$emit("close");
        } else {
          this.$toasted.show(
            this.$lang.general_ToastError,
            this.$toastedFailure
          );
        }

        this.sendingInvite = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .invite-modal-header {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
  }

  .invite-modal-body {
    margin-top: 2rem;
  }

  .invite-modal-sub-header {
    color: $grey-500;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .invite-modal-list-container {
    overflow-y: auto;
    max-height: 20rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .invite-modal-list-item {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }

  .invite-modal-list-item-error {
    border: 1px solid $salmon-500;
    border-radius: 0.5rem;
  }

  .invite-modal-list-item-input {
    margin-left: 1rem;
  }

  .invite-modal-list-item-delete {
    color: $grey-500;
    cursor: pointer;
    transition: color var(--transition-duration);
    flex: none;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;

    &:hover {
      color: $blue-500;
    }
  }

  .invite-modal-actions {
    display: flex;
    justify-content: space-between;
  }

  .invite-modal-button-icon {
    margin-right: 0.25rem;
    height: 24px;
    width: 24px;
  }

  .invite-modal-send-button {
    margin-top: 1.5rem;
    margin-left: auto;
  }
</style>
